@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .h-fit-min-screen {
    @apply h-fit min-h-[100vh];
  }
  .page-container {
    @apply bg-bg_lightgray pt-16 pb-12 px-5 md:pl-24 md:pr-10 h-fit-min-screen 4xl:w-[2400px] 4xl:mx-auto 4xl:pl-0;
  }

  .page-container-no-padding-bottom {
    @apply bg-bg_lightgray pt-16 !pb-0 px-5 md:pl-24 md:pr-10 h-fit-min-screen 4xl:w-[2400px] 4xl:mx-auto 4xl:pl-0;
  }

  .editor-menu-btn {
    @apply bg-white text-black text-sm p-2 h-10 w-10 hover:bg-gray-200 rounded-sm transition duration-150 flex justify-center items-center;
  }
  .h-full-init {
    @apply h-[calc(100vh-8.25rem-44px)];
  }

  .h-full-init-with-tabs {
    @apply h-[calc(100vh-9.5rem-112px)];
  }
  .editor-menu-btn {
    @apply bg-white text-black text-sm p-2 h-10 w-10 hover:bg-gray-200 rounded-sm transition duration-150 flex justify-center items-center;
  }

  .is-active {
    @apply bg-gray-200 text-sky-500 transition duration-150;
  }

  .tooltip-btn {
    @apply bg-black text-white border border-black text-sm px-2 py-1 transition duration-150;
  }
  .tooltip-is-active {
    @apply bg-white text-black border border-black text-sm px-2 py-1 transition duration-150;
  }
}
